<template>
  <vue-final-modal class="modal-demo-game">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'label') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('demo-game')" />
      </div>
    </div>

    <div class="vfm__body">
      <p class="text">{{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}</p>

      <div class="actions">
        <button-base type="primary" size="md" @click.once="confirm">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, `${modalType}`) }}
        </button-base>

        <button-base type="secondary-1" size="md" @click="closeModal('demo-game')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'demo') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['demoGame']>;
    defaultLocaleData: Maybe<CIModalsContent['demoGame']>;
  }>();

  const emit = defineEmits(['playReal']);
  const { getContent, localizePath } = useProjectMethods();
  const profileStore = useProfileStore();
  const walletStore = useWalletStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { activeAccount } = storeToRefs(walletStore);
  const router = useRouter();

  const modalType = computed<'real' | 'deposit' | 'registration'>(() => {
    if (!isLoggedIn.value) return 'registration';
    if (activeAccount.value?.balance ?? 0 > 0) return 'real';
    return 'deposit';
  });

  const { openWalletModal, closeModal } = useModalStore();
  const confirm = async (): Promise<void> => {
    if (modalType.value === 'real') emit('playReal');
    else if (modalType.value === 'deposit') await openWalletModal('deposit');
    else if (modalType.value === 'registration') await router.push(localizePath('/sign-up'));

    await closeModal('demo-game');
  };
</script>

<style src="~/assets/styles/components/modal/demo-game.scss" lang="scss" />
